import React from "react"
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import { Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"

import Layout from "../components/layout"
import Title from "../components/title"
import Seo from "../components/seo"

const useStyles = makeStyles(theme => ({
  container: {
    height: "100%",
  },
  box: {
    height: "100%",
  }
}))

const NotFoundPage = () => {
  const classes = useStyles()
  return (
    <Layout>
      <Seo title="404: Nie znaleziono" />
      <Container maxWidth="lg" className={classes.container}>
        <Box
          height="100%"
          display="flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
        >
          <Title>404: Nie znaleziono</Title>
          <Typography>Przykro nam, strona której szukasz nie istnieje</Typography>
          <Link to="/">
            <Typography>Wróć na stronę główną...</Typography>
          </Link>
        </Box>
      </Container>
    </Layout>
  )
}

export default NotFoundPage
